import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import american from "./../../assets/images/americanMade.png";
import emailIcon from "./../../assets/icons/emailIcon.png";
import backArrow from "./../../assets/icons/backArrow.png";
import Input from "../../components/shared/Input";
import dSocialLogo from "./../../assets/icons/dDriveLogo.svg";
import l1feMinistry from "./../../assets/images/l1feMinistry.svg";

import instance from "../../utils/axios";
import { API } from "../../utils/apiendpoints";
import { toast } from "react-toastify";

const LoginPage = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange", reValidateMode: " " });

  const onFormSubmit = (data) => {
    setLoader(true);
    instance
      .post(API.forgotPassword, data)
      .then((res) => {
        setLoader(false);
        toast.success("Reset password mail sent successfully!!");
      })
      .catch((err) => setLoader(false));
  };

  return (
    <>
      <Container fluid className="vh-100 login-wrapper wrapper reset-password">
        <Row className="h-100">
          <Col xl={4} lg={5} className="left-col">
            <div className="d-flex flex-column justify-content-between h-100">
              <h2 className="p-4 text-dark">Socialize without censorship</h2>
              <div className="d-flex justify-content-between mb-3 align-items-center">
                <img src={l1feMinistry} width={100} height={30} alt="l1fe ministy" />
                <img src={dSocialLogo} width={80} height={20} alt="dsocial logo" />
              </div>
            </div>
          </Col>
          <Col xl={8} lg={7} className="right-col">
            <Container className="h-100">
              <Row className="h-100">
                <Col xl={{ span: 6, offset: 3 }} className="auth-block">
                  <div className="w-100">
                    <div className="heading-md mb-32">
                      <span
                        className="cursor-pointer"
                        onClick={() => navigate("/login")}
                      >
                        <img src={backArrow} alt="back_btn" />
                      </span>
                      <span>Reset your password</span>
                    </div>
                    <form onSubmit={handleSubmit(onFormSubmit)}>
                      <Input
                        icon={emailIcon}
                        name="email"
                        placeholder="Enter your email address"
                        errors={errors}
                        register={register("email", {
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: "Please enter a valid Email",
                          },
                          required: {
                            value: true,
                            message: "This is required",
                          },
                        })}
                      />

                      <Button
                        className="custom-btn mb-32 mt-24"
                        variant="dark"
                        type="submit"
                      // onClick={() => setStep((prev) => prev + 1)}
                      >
                        {loader ? (
                          <>
                            <span
                              className="spinner-border me-2 spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </>
                        ) : (
                          "Reset Password"
                        )}
                      </Button>
                    </form>
                    <div className="text-center">
                      <img
                        className="bottom-img"
                        src={american}
                        alt="american_icon"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LoginPage;
