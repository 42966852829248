import React, { useState } from "react";

const PasswordInput = ({ icon, name, placeholder, register, errors }) => {

  const [passwordType, setpasswordType] = useState('password');

  const handleShowPassword = () => {
    passwordType === 'password' ? setpasswordType('text') : setpasswordType('password');
  }
  return (
    <>
      <div className="common-input mb-2 position-relative">
        <div className='normal-border d-flex justify-content-between flex-direction-row' style={{
          backgroundColor: "#FAFAF8",
        }}>
          <input type={passwordType} name={name} placeholder={placeholder} className="border border-0" {...register} autoComplete="on" />
          <div className="align-self-center text-decoration-underline px-2" onClick={handleShowPassword} role="button">{passwordType === 'password' ? 'Show' : 'Hide'}</div>
        </div >
      </div >
    </>
  );
};

export default PasswordInput;
